<template>
  <Header
      is-home="true"
      title="How can we help you?"
      bg-image="contact-banner.jpg"/>
  <main>
    <section class="container-xl intro-section">
      <div class="row align-items-center justify-content-between">
        <div class="col-12 col-md-5">
          <p>We're located at: <br>
            14606 US Highway 19 S Thomasville, Georgia 31757</p>
          <p class="mb-4">
            Our showroom hours are:
            <br>Monday through Friday 8 a.m. to 5:30 p.m. <br>
            Saturday 9 a.m. to 3 p.m.</p>
          <div class="map-responsive">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27402.782436807272!2d-83.944666!3d30.848936!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x77f7a3747b17ed14!2sThomasville%20Bedding%20Company!5e0!3m2!1sen!2sus!4v1649133527423!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <p class="mt-4">If you can't come by, please call <a href="tel:12292268650" class="">1-229-226-8650</a>
            or send an email to <a
              href="mailto:thomasvillebedding@gmail.com" class="">
              thomasvillebedding@gmail.com</a> and we promise to get back with you as soon as we can.</p>
        </div>
        <div class="col-12 col-md-5 mb-4 mb-md-0 order-1">
          <img src="../assets/img/jonathan.jpg" alt=""
               class="img-fluid shadow-lg mx-auto">
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import Header from '@/components/Header'

export default {
  name: 'Contact',
  components: { Header },
  mounted () {
    document.title = 'Thomasville Bedding'
  }
}
</script>

<style scoped>
.map-responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.map-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}
</style>
